export default class FSalesman {
  constructor(
      id,
      sourceId,
      spcode,
      spname,
      avatarImage,
      salesType,
      fdivisionBean,
      address1,
      district1,
      city1,
      state1,
      phone,
      countryCode,
      mobile,
      whatsApp,
      email,
      joinDate,
      lastTrans,
      bornPlace,
      bornDate,
      religion,
      statusActive,
      webServiceActive,
      kassaStatusOpen,
      kassaIp,
      ftPriceAlthBean,
      noPromotionRules,
      vendorcovered,
      targetEffCall,
      targetSalesAmount,
      targetDipotongRetur,
      childOf,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.sourceId = sourceId;
    this.spcode = spcode;
    this.spname = spname;
    this.avatarImage = avatarImage;
    this.salesType = salesType;
    this.fdivisionBean = fdivisionBean;
    this.address1 = address1;
    this.district1 = district1;
    this.city1 = city1;
    this.state1 = state1;
    this.phone = phone;
    this.countryCode = countryCode;
    this.mobile = mobile;
    this.whatsApp = whatsApp;
    this.email = email;
    this.joinDate = joinDate;
    this.lastTrans = lastTrans;
    this.bornPlace = bornPlace;
    this.bornDate = bornDate;
    this.religion = religion;
    this.statusActive = statusActive;
    this.webServiceActive = webServiceActive;
    this.kassaStatusOpen = kassaStatusOpen;
    this.kassaIp = kassaIp;
    this.ftPriceAlthBean = ftPriceAlthBean;
    this.noPromotionRules = noPromotionRules;
    this.vendorcovered = vendorcovered;
    this.targetEffCall = targetEffCall;
    this.targetSalesAmount = targetSalesAmount;
    this.targetDipotongRetur = targetDipotongRetur;
    this.childOf = childOf;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}
